import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import SliderFooter from "../Slider/SliderFooter";
import logo from "../../assets/eggs-and-waffles-logo.svg";

const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <Fragment>
      <section
        className={
          "pt-0 section__footer pb-0 " + (props.class ? props.class : "")
        }
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <h5
              exact
              class="navbar-brand font__size--24 text__24-1024 text__24-sm playfair bold mb-0 "
            >
              <img
                src={logo}
                style={{
                  display: "inline-block",
                  height: "100px",
                  width: "100px",
                }}
              />
            </h5>

            <ul className="list__sosmed">
              <li>
                <a
                  href="https://facebook.com/eggsandwafflesgilroy"
                  target="_blank"
                >
                  <img src="./../images/icon (1).png" alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/eggsandwafflesgilroy"
                  target="_blank"
                >
                  <img src="./../images/icon (3).png" alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>
          <hr className="hr__line my-5" />
          <div className="row">
            <div className="mb-4 mb-lg-0 col-sm-6 order-md-1 order-2 col-md">
              <h5 className="medium font__size--14 text__14-1024 mb-4">
                Contact
              </h5>
              <ul className="list__menu">
                <li>
                  <div className="d-flex">
                    <p className="normal font__size--12 text__28-1024 text-left mt-1 mb-1">
                      <b>Hours:</b> 7am - 2pm
                      <br />
                      <b>7 Days a week</b>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <img
                      src="./../images/Call.png"
                      className="object-fit-content mr-3"
                      alt=""
                    />
                    <span className="normal font__size--12 text__12-1024 color__gray-1 d-inline-block">
                      <a href="tel:408-337-1800">408-337-1800</a>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <img
                      src="./../images/Message.png"
                      className="object-fit-content mr-3"
                      alt=""
                    />
                    <span className="normal font__size--12 text__12-1024 color__gray-1 d-inline-block">
                      <a href="mailto:Eggsandwafflesinc@gmail.com">
                        Eggsandwafflesinc@gmail.com
                      </a>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <img
                      src="./../images/location (3) 1.png"
                      className="object-fit-content mr-3"
                      alt=""
                    />
                    <span className="normal font__size--12 text__12-1024 color__gray-1 d-inline-block">
                      <a
                        href="https://www.google.com/maps/place/Eggs+%26+Waffles/@37.0143561,-121.588337,17z/data=!3m1!4b1!4m6!3m5!1s0x8091e30055e0ec0d:0x614c5c9c3aa24c51!8m2!3d37.0143562!4d-121.5834661!16s%2Fg%2F11vwsxvmpt?entry=ttu"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        741 1st St Gilroy CA 95020
                      </a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="mb-4 mb-lg-0 order-1 order-md-2 col-md">
              <h5 className="medium font__size--14 text__14-1024 mb-4">
                About Us
              </h5>
              <p className="normal font__size--12 text__12-1024 color__gray-1">
                A cozy and inviting spot that serves up a hearty breakfast and
                lunch menu, featuring a variety of eggs, waffles, toasts,
                burgers, and more. It's a perfect place for a quick bite or a
                leisurely meal.
              </p>
            </div>
          </div>

          <hr className="hr__line mb-0 mt-5" />
        </div>
        <div className="bottom text-center normal font__size--12 text__12-1024 color__gray-1 py-4">
          <div className="container">
            Copyright <b className="color__yellow">Eggs and Waffles</b>. {year}{" "}
            All Right Reserved
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
