import * as yup from "yup";

const contactSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  message: yup
    .string()
    .required("Please let us know what is this regarding?")
    .min(10, "Too short")
    .max(5000, "Too long"),
});

const schemas = {
  contact: contactSchema,
};
schemas.contact = contactSchema;

export default schemas;
