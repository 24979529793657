function createMenuItem(title, price, detail, img) {
    return { id: Math.random() * 1000 + 1, title, price, detail, img };
}

const menu = [
    {
        title: "Popular Combos",
        detail: "",
        items: [
            createMenuItem(
                "Griddle Sampler",
                20,
                "Pick any Three - pancake, french toast or waffle",
            ),
            createMenuItem(
                "Griddle Combo",
                20,
                "Two eggs any style, choice of Bacon or Sausage and choice of any Pancake, French Toast or Waffle. (+2 for flavored griddle item)",
            ),
        ],
    },
    {
        title: "Kids Menu",
        detail: "",
        items: [
            createMenuItem(
                "Mickey Pancake & Bacon",
                10,
                "Add Fresh Strawberry, blueberry, banana or chocolate chips (+2)",
            ),
            createMenuItem(
                "French Toast and Bacon",
                10,
                "Add Fresh Strawberry, blueberry, banana or or chocolate chips (+2)",
            ),
            createMenuItem("Grilled Cheese & Fries", 10, "",),
            createMenuItem("Chicken Tender & Fries", 10, "",),

        ],
    },
    {
        title: "Pancakes",
        detail: "",
        items: [
            createMenuItem(
                "Buttermilk Pancakes",
                12,
                "Topped with powdered sugar and butter",
            ),
            createMenuItem(
                "All Fruit Pancakes",
                15,
                "Fresh strawberries, blueberries, bananas topped with powdered sugar, butter & cream cheese drizzle",
            ),
            createMenuItem(
                "Red Velvet Pancakes",
                14,
                "Hershey’s chocolate chips topped with powdered sugar, butter & cream cheese drizzle",
            ),
            createMenuItem(
                "Cookies & Cream Pancakes",
                14,
                "Cookie crumbles topped with powdered sugar, butter & cream cheese drizzle",
            ),
            createMenuItem(
                "S'mores & More Pancakes",
                14,
                "Packed with graham crackers, marshmallow fluff, topped with powdered sugar, butter & Nutella drizzle",
            ),
            createMenuItem(
                "Chocolate Chip Pancakes",
                14,
                "Topped with Nutella drizzle & powdered sugar Topped with powdered sugar, butter & Nutella drizzle",
            ),
        ],
    },
    {
        title: "Waffles",
        detail: "",
        items: [
            createMenuItem(
                "Belgian Waffle",
                12,
                "Topped with powdered sugar and butter",
            ),
            createMenuItem(
                "All Fruit Waffle",
                16,
                "Fresh strawberries, blueberries, banana topped with powdered sugar, butter and cream cheese drizzle",
            ),
            createMenuItem(
                "Red Velvet Waffle",
                16,
                "Hershey’s chocolate chips topped with powdered sugar, butter & cream cheese drizzle.",
            ),
            createMenuItem(
                "Caramel Banana & Pecan Waffle",
                16,
                "Topped with powdered sugar, butter & caramel drizzle.",
            ),
        ],
    },
    {
        title: "French Toast",
        detail: "",
        items: [
            createMenuItem(
                "The Original",
                16,
                "Topped with powdered sugar & butter.",
            ),
            createMenuItem(
                "Banana & Chocolate chip",
                14,
                "Topped with powdered sugar, butter & nutella drizzle",
            ),
            createMenuItem(
                "All Fruit",
                16,
                "Fresh strawberries, blueberries, banana topped with powdered sugar, butter and cream cheese drizzle",
            ),
        ],
    },
    {
        title: "Skillets",
        detail: "",
        items: [
            createMenuItem(
                "The Hangover",
                16,
                "Ham, bacon, sausage, onion, mushroom, pasilla pepper, bell peppers, tomatos on bed of seasoned potatoes, cheddar cheese, topped with two eggs any style",
            ),
            createMenuItem(
                "Chorizo Skillet",
                19,
                "Chorizo, onion, fresh jalapeño, on bed of seasoned potatoes, pepper jack cheese, topped with two eggs any style, salsa , avocado, sour cream",
            ),
            createMenuItem(
                "NY Steak",
                21,
                "Steak, onions, bell peppers on bed of seasoned potatoes, cheddar cheese, eggs, topped with guacamole, jalapeños, pico de gallo, and sour cream",
            ),
            createMenuItem(
                "Salmon",
                20,
                "Grilled salmon on bed of seasoned potatoes, topped with eggs, red onions, Hollandaise, capers, spinach, and fresh dill",
            ),
        ],
    },
    {
        title: "Egg Dishes",
        detail:
            "All egg dishes served with a side of hashbrowns or seasoned potatoes and toast",
        items: [
            createMenuItem(
                "Two Egg Breakfast",
                12,
                "Make it a cheese scramble (+$2)",
            ),
            createMenuItem("Bacon and Eggs", 15, "Make it a cheese scramble (+$2)"),
            createMenuItem(
                "Sausage and Eggs",
                15,
                "Sub chicken apple, linguica or Italian (+2)",
            ),
            createMenuItem(
                "Ham and Eggs",
                15,
                "Make it a cheese scramble (+2)",
            ),
        ],
    },
    {
        title: "Specialities",
        detail:
            "All egg dishes served with a side of hashbrowns or seasoned potatoes and toast",
        items: [
            createMenuItem(
                "Chicken Fried Steak",
                18,
                "Lightly breaded steak, golden fried, and topped with homemade country gravy & two eggs any style.",
            ),
            createMenuItem(
                "NY Steak",
                21,
                "Two eggs any style, NY steak, sauteed onions & mushrooms.",
            ),
            createMenuItem(
                "Huevos Ranchero",
                16,
                "Two eggs any style served on a corn tortilla topped withbeans, avocado & salsa (Add chorizo +3)",
            ),
            createMenuItem(
                "Chilaquiles",
                16,
                "Corn tortillas served in roasted tomatillo sauce topped with two eggs any style, salsa, sour cream and queso fresco",
            ),
            createMenuItem(
                "Corned Beef Hash",
                18,
                "Two eggs any style with your choice of toast",
            ),
            createMenuItem(
                "Country Breakfast",
                18,
                "Two eggs any style, sausage, biscuit & housemade gravy & side potatoes",
            ),
            createMenuItem(
                "Southwestern Burrito",
                16,
                "Chorizo, eggs, black beans, pasilla pepper, & Pepperjack cheese on a flour tortilla",
            ),
            createMenuItem(
                "The Big Breakfast",
                22,
                "Two eggs any style, with your choice of bacon or sausage, and one Belgian waffle, two original French toast, or two buttermilk pancakes. (+2 for flavored griddle item)",
            ),
        ],
    },
    {
        title: "Omelets",
        detail:
            "All egg dishes Served with a side of hashbrowns or seasoned potatoes and toast",
        items: [
            createMenuItem(
                "Denver",
                18,
                "Ham, onions, bell pepper, tomato, mushroom, & cheddar cheese",
            ),
            createMenuItem(
                "The Mexican",
                18,
                "Chorizo, pasilla pepper, black beans, pepperjack topped with avocado, salsa & sour cream.",
            ),
            createMenuItem(
                "Veggie",
                18,
                "Spinach, mushroom, tomato, bell pepper, and onions topped with cheddar and Swiss cheese.",
            ),
            createMenuItem(
                "Meat Lovers",
                18,
                "Bacon, ham, sausage, mushroom & cheddar cheese topped with avocado.",
            ),
            createMenuItem(
                "Mushroom",
                18,
                "Bacon, mushroom  & cheddar cheese topped with avocado",
            ),
            createMenuItem(
                "Chicken Apple Sausage",
                18,
                "Chicken apple sausage, mushrooms, green Onions, cheese, avocado and sour cream",
            ),
        ],
    },
    {
        title: "Scrambles",
        detail:
            "All egg dishes served with a side of hashbrowns or seasoned potatoes and toast",
        items: [
            createMenuItem(
                "The Border",
                18,
                "Two eggs scrambled with chorizo, tomato, jalapeños, green onions, topped with cheddar cheese, salsa, sour cream",
            ),
            createMenuItem(
                "The Joe",
                18,
                "Sauteed ground beef*, onion, mushroom, spinach and country potatoes scrambled with eggs* and topped with Monterey Jack cheese.",
            ),
            createMenuItem(
                "Gilroy Scramble",
                18,
                "Tomatoes, chicken apple sausage, mushrooms, green onions, roasted garlic, Monterey Jack cheese, topped with avocado and sour Cream",
            ),
            createMenuItem(
                "Veggie Scramble",
                18,
                "Spinach, tomatoes, mushrooms, bell pepper, and onions topped with cheddar and  Swiss cheese.",
            ),
        ],
    },
    {
        title: "Benedicts",
        detail: "Served with a side of hashbrowns or seasoned potatoes",
        items: [
            createMenuItem(
                "Classic Benedict",
                18,
                "Two poached eggs over Canadian bacon on a toasted English muffin topped with Hollandaise sauce.",
            ),
            createMenuItem(
                "California Benedict",
                18,
                "Two poached eggs over avocado, bacon, and grilled tomato on a toasted English muffin topped with Hollandaise sauce.",
            ),
            createMenuItem(
                "Lox Benedict",
                20,
                "Two poached eggs over sauteed spinach tomato smoked salmon on a toasted English muffin topped with Hollandaise sauce.",
            ),
            createMenuItem(
                "Crab Cake Benedict",
                20,
                "Two poached eggs over avocado, tomato, crab cakes on atoasted English muffin topped with Hollandaise sauce.",
            ),
            createMenuItem(
                "Veggie Benedict",
                18,
                "Two poached eggs over grilled portabello mushrooms, spinach, tomato on a toasted English muffin topped with Hollandaise sauce.",
            ),
        ],
    },
    {
        title: "Avocado Toast",
        detail: "",
        items: [
            createMenuItem(
                "Caprese Avocado Toast",
                16,
                "Cherry tomatoes, fresh mozzarella, avocado, basil tossed in olive oil & topped with our homemad glazed balsamic dressing on coastside sourdough",
            ),
            createMenuItem(
                "Pesto Avocado Toast",
                17,
                "Cherry tomatoes, onions, avocado tossed in our honemade basil pesto & topped with two eggs any style on coastside sourdough",
            ),
            createMenuItem(
                "Smoked Salmon Avocado Toast",
                19,
                "Cucumbers, onions, fresh dill tossed in our homemade lemon vinaigrette, Lox cream cheese, avocado, smoked salmon topped with fried capers, fried lemon & fried basil leaf served on crispy coastside sourdough",
            ),
        ],
    },
    {
        title: "Burgers",
        detail: "Served with french fries, green salad, coleslaw, fruit (+2), garlic fries (+2), onion rings (+2), or soup of the day (+2)",
        items: [
            createMenuItem(
                "Bacon Cheeseburger",
                17,
                "Half pound Angus beef, bacon, lettuce, pickles, tomato, red onions, mayo and cheddar cheese on a brioche bun",
            ),
            createMenuItem(
                "The Original Burger",
                15,
                "Half pound Angus beef, lettuce, pickles, tomato, red onions, mayo served on a brioche bun",
            ),
            createMenuItem(
                "Garlic City Burger",
                19,
                "Portobello mushroom, caramelized red onion, egg, cheddar cheese, roasted garlic, grilled jalapeño, lettuce, tomato, mayo served on a brioche bun.",
            ),
        ],
    },
    {
        title: "Sandwiches",
        detail: "Served with french fries, green salad, coleslaw, fruit (+2), garlic fries (+2), onion rings (+2), or soup of the day (+2)",
        items: [
            createMenuItem(
                "California Chicken",
                17,
                "Avocado and melted Swiss cheese served on a French roll",
            ),
            createMenuItem(
                "Philly Steak",
                17,
                "Grilled thin sliced steak with onions, bell peppers, mushrooms and Monterey Jack cheese",
            ),
            createMenuItem(
                "Caprese Pesto Chicken",
                17,
                "Grilled chicken, fresh mozzarella, roasted tomatoes, house made basil pesto on focaccia bread",
            ),
            createMenuItem(
                "Patty Melt",
                16,
                "Half pounder certified Angus beef, cheddar cheese, Thousand Island and grilled onions on rye bread",
            ),
            createMenuItem(
                "BLTA",
                17,
                "Bacon, lettuce, tomatoes, avocado and mayo on toasted wheat bread",
            ),
            createMenuItem(
                "Turkey Club",
                17,
                "Triple decker with turkey, bacon, ham, Swiss cheese, lettuce, tomatoes, avocado & mayo served on wheat bread.",
            ),
            createMenuItem(
                "BBQ Pulled Pork",
                17,
                "Coleslaw, apple, pineapple, carrot, cabbage tossed in vinaigrette on a brioche bun",
            ),
            createMenuItem(
                "Blackened Salmon BLTA",
                20,
                "Blackened salmon, mayo, lettuce, tomatoes, bacon & avocado on focaccia bread",
            ),
            createMenuItem(
                "Guacamole Garden Grill",
                16,
                "Portobello mushrooms, provolone cheese, roasted red peppers, grilled red onions, and guacamole served on wheat bread",
            ),
            createMenuItem(
                "NY Steak Sandwich",
                21,
                "Grilled onions, spring mix, tomato, mushroom and garlic aioli served on a French roll",
            ),
        ],
    },
    {
        title: "Salads",
        detail:
            "Served with a side of hashbrowns or seasoned potatoes. Add Chicken $6 or Salmon $9",
        items: [
            createMenuItem(
                "Chicken Caeser Salad",
                16,
                "Romaine Lettuce tossed in caesar dressing, topped with croutons,grilled chicken breast, onions & parmesan cheese. (sub Salmon +4)",
            ),
            createMenuItem(
                "Cobb Salad",
                18,
                "Grilled chicken breast, tomato, avocado, bacon and hard boiled egg topped with blue cheese crumbles on a bed of mixed greens. Choice of dressing on side",
            ),
            createMenuItem(
                "Tostada Salmon",
                19,
                "Romaine lettuce, cherry tomatoes, jalapeño, black beans, black olives tossed in our homemade creamy cilantro dressing topped with avocado, salsa & queso fresco (Chicken or Veggie style available",
            ),
            createMenuItem(
                "Garden Salad",
                10,
                "Season mixed greens, tomatoes, cucumber, carrots & onions with your choice of dressing on the side (Add Chicken (+6), Add Salmon (+9)",
            ),
            createMenuItem(
                "Soup Of The Day",
                8,
                "Served with our housemade focaccia upon request, bread bowl available",
            ),
        ],
    },
    {
        title: "Coffee & Espresso",
        detail: "",
        items: [
            createMenuItem("Espresso", 4, ""),
            createMenuItem("Americano", 4, ""),
            createMenuItem("Latte", 5, ""),
            createMenuItem("Vanilla Latte", 6, ""),
            createMenuItem("Caramel Latte", 6, ""),
            createMenuItem("Lavender Latte", 6, ""),
            createMenuItem("Cappuccino", 5, ""),
            createMenuItem("Mocha", 6, ""),
            createMenuItem("Monkey Mocha", 6, ""),
            createMenuItem("House Mocha", 6, ""),
            createMenuItem("Black Cofee", 3, ""),
            createMenuItem("Hot Tea", 4, ""),
            createMenuItem("Hot Chocolate", 5, ""),
            createMenuItem("Chai Tea Latte", 6, ""),
        ],
    },
    {
        title: "Beverages",
        detail: "",
        items: [
            createMenuItem("Juices", 4, "Orange, Apple, Pineapple, Mango"),
            createMenuItem("Milk", 4, ""),
            createMenuItem("Iced Tea", 4, ""),
            createMenuItem("Fountain Drinks", 4, ""),
            createMenuItem("MilkShakes", 8, "Vanilla, Chocolate, Strawberry topped with whip cream"),
            createMenuItem("Root Beer Float", 7, ""),
        ],
    },
    {
        title: "Beer & Wine",
        detail: "",
        items: [
            createMenuItem("Domestic/Imported", 5, ""),
            createMenuItem("IPA", 9, ""),
            createMenuItem("Wine - Single Serve", 8, ""),
        ],
    },
    {
        title: "Mimosa",
        detail: "",
        items: [
            createMenuItem("Orange Mimosa", 8, ""),
            createMenuItem("Mango Mimosa", 8, ""),
            createMenuItem("Pomegranate Mimosa", 8, ""),
            createMenuItem("Grapefruit Mimosa", 8, ""),
            createMenuItem("Mimosa Flight", 20, "Pick any four"),
            createMenuItem("Champagne", 8, ""),
        ],
    },
    {
        title: "Sides",
        detail: "",
        items: [
            createMenuItem("Two Eggs", 5, ""),
            createMenuItem("Brown Sugar / Raisin Oatmeal", 10, ""),
            createMenuItem("Bacon", 7, ""),
            createMenuItem("Ham", 7, ""),
            createMenuItem("Sausage", 7, ""),
            createMenuItem("Biscuits and Gravy", 7, ""),
            createMenuItem("Avocado", 4, ""),
            createMenuItem("Fruit", 6, ""),
            createMenuItem("Hashbrown", 5, ""),
            createMenuItem("House Potatoes", 5, ""),
            createMenuItem("Hollandaise", 3, ""),
            createMenuItem("Coleslaw", 5, ""),
            createMenuItem(
                "Gilroy Potatoes",
                9,
                "Onions, jalapeño, cheddar cheese topped with sour cream and garlic aioli",
            ),
            createMenuItem("Cottage Cheese", 4, ""),
            createMenuItem("Toast & Jelly", 4, ""),
            createMenuItem("Bagel w/ Cream Cheese", 5, ""),
            createMenuItem("French Fries", 6, ""),
            createMenuItem("Onions Rings", 6, ""),
            createMenuItem("Garlic Fries", 6, ""),
            createMenuItem("Green Salad", 6, ""),
        ],
    },
    {
        title: "Substitutions",
        detail: "",
        items: [
            createMenuItem("Egg Whites", 2, ""),
            createMenuItem("Fruit", 2, ""),
            createMenuItem("Pancakes for Toast", 2, ""),
            createMenuItem("Onion Rings", 2, ""),
            createMenuItem("Garlic Fries", 2, ""),
            createMenuItem("Biscuit or Begal for Toast", 2, ""),
        ],
    },
];

export default menu;
