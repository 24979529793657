import React, { Fragment } from "react";

import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../component/Footer/Footer";
import Testimoni from "../../component/Slider/Testimoni";
import { NavLink } from "react-router-dom";
const Index = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black position-relative pl-md-5">
          <span className="backdrop"></span>
          <img src="./../images/home-bg-1.jpeg" className="path__1" alt="" />
          <div className="position-relative z-2">
            <Navbar />

            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p className="medium font__size--18 text__18-1024 color__white">
                      Welcome Back to{" "}
                      <span className="playfair bold">
                        <span className="color__yellow">Eggs & Waffles</span>
                      </span>
                    </p>
                    <h1 className="bold font__size--80 text__80-1024 text__80-md text__80-sm text__80-xs playfair color__white mb-4">
                      Eat Good <br /> Feel Great!
                    </h1>
                    <p className="normal font__size--16 text__16-1024 color__white mb-4">
                      A cozy and inviting spot that serves up a hearty breakfast
                      and lunch menu, featuring a variety of eggs, waffles,
                      toasts, burgers, and more. It's a perfect place for a
                      quick bite or a leisurely meal.
                    </p>
                    <NavLink
                      to="/menu"
                      className="font__size--20 text__20-1024 medium btn btn__yellow color__black shadow btn__original"
                    >
                      Explore Menu
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-5 mb-4 my-md-auto">
                <img
                  src="./../images/pancakes-honey.jpeg"
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb-4">
                  Serving Up Happiness, One Pancake at a Time
                </h2>
                <p className="normal font__size--16 text__16-1024 color__gray-1 mb-3">
                  Indulge in the ultimate breakfast experience with our light and fluffy pancakes, made from scratch with the finest ingredients. Perfectly golden and deliciously soft, our pancakes are a treat for your taste buds.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="wrapper__choose-wrap position-relative bg__gray-2 z-2">
              <h2 className="text-center bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb__5">
                Why People Choose Us
              </h2>

              <div className="row justify-content-center">
                <div className="col-md-11 col-xl-9">
                  <div className="row justify-content-center">
                    <div className="col-md-6 mb-4 mb-md-0">
                      <div className="wrapper__choose text-center">
                        <div className="icon mb-4 mx-auto">
                          <img src="./../images/vegetarian (1).png" alt="" />
                        </div>
                        <h5 className="bold font__size--24 text__24-1024 text__24-sm playfair mb-2">
                          Always Fresh Ingredients
                        </h5>
                        <p className="normal font__size--14 text__14-1024 color__gray-1">
                          Our commitment to using only the freshest and highest
                          quality ingredients ensures that every dish served is
                          bursting with flavor and made to perfection.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wrapper__choose text-center">
                        <div className="icon mb-4 mx-auto">
                          <img src="./../images/food-tray.png" alt="" />
                        </div>
                        <h5 className="bold font__size--24 text__24-1024 text__24-sm playfair mb-2">
                          Variety for Every Palate
                        </h5>
                        <p className="normal font__size--14 text__14-1024 color__gray-1">
                          Our diverse menu caters to every palate, ensuring
                          there's something for everyone to enjoy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer class={"mt__5"} />
      </div>
    </Fragment>
  );
};

export default Index;
