import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/eggs-and-waffles-logo.svg";

const Navbar = (props) => {
  return (
    <Fragment>
      <nav
        className="navbar navbar-expand-lg wrapper__navbar position-relative z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <NavLink
            to="/"
            exact
            class="navbar-brand font__size--24 text__24-1024 text__24-sm color__white playfair bold"
          >
            <img
              src={logo}
              style={{
                display: "inline-block",
                height: "130px",
                width: "130px",
              }}
            />
          </NavLink>

          <div className="d-flex d-lg-none align-items-center">
            <button
              className={"navbar-toggler nav__button position-relative "}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/"
                  exact
                  class="nav-link color__white medium font__size--24 text__14-1024 "
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  class="nav-link color__white medium font__size--24 text__14-1024 "
                >
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/menu"
                  class="nav-link color__white medium font__size--24 text__14-1024 "
                >
                  Menu
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact"
                  class="nav-link color__white medium font__size--24 text__14-1024 "
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          <NavLink
            to={{
              pathname:
                "https://www.clover.com/online-ordering/eggs-and-waffles-gilroy",
            }}
            target="_blank"
            className="medium font__size--24 text__14-1024 color__white btn btn__outlined--white no__opacity shadow h__black btn__head"
          >
            Order Online
          </NavLink>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
