import React, { useState, Fragment } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../component/Footer/Footer";
import schemas from "../../constants/contact.schema";
const axios = require("axios").default;

const Contact = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState({
    message: "",
    type: "",
  });
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  function processSubmitMessage(message, type, isSubmitting) {
    setSubmitMessage({
      message,
      type,
    });
    setIsSubmitting(isSubmitting);

    if (!isSubmitting) {
      setTimeout(() => {
        setSubmitMessage({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }

  const handleSubmit = (data, { resetForm }) => {
    processSubmitMessage("", "", true);

    axios
      .post("/api/v1/contact", data)
      .then(function (response) {
        processSubmitMessage(
          "Your message has been sent! We will get back to you soon.",
          "success",
          false,
        );
        resetForm();
      })
      .catch(function (error) {
        processSubmitMessage(
          "Something went wrong. Please try again.",
          "danger",
          false,
        );
      });
  };

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black position-relative">
          <span className="backdrop"></span>
          <img src="./../images/contact-bg.jpeg" className="path__1" alt="" />
          <div className="position-relative z-2">
            <Navbar />
            <div className="container">
              <h5 className="mb-0 color__white normal font__size--16 text__16-1024">
                <span className="opacity__6">Home</span> {" > "}
                <span className="color__yellow">Contact Us</span>
              </h5>
            </div>
            <section>
              <div className="container">
                <div className="text-center">
                  <h1 className="bold font__size--80 text__80-1024 text__80-md text__80-sm text__80-xs playfair color__white mb-4">
                    Contact Us
                  </h1>
                  <p className="normal font__size--28 text__28-1024 color__white">
                    Reach out to us for any inquiries or feedback. We would love
                    to hear from you!
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm playfair text-center">
                  Contact Information
                </h2>
                <p className="normal font__size--22 text__28-1024 text-center mt-3 mb-5">
                  <b>Hours:</b> 7am - 2pm | <b>7 Days a week</b>
                </p>

                <div className="row">
                  <div className="col-md-4">
                    <div className="wrapper__contact-list text-center">
                      <div className="icon mb-3 mx-auto">
                        <img src="./../images/img (1).png" alt="" />
                      </div>
                      <div className="metadata">
                        <h5 className="bold font__size---28 text__28-1024 playfair">
                          Email Us
                        </h5>
                        <p className="normal font__size--20 text__20-1024">
                          <a href="mailto:Eggsandwafflesinc@gmail.com">
                            Eggsandwafflesinc@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="wrapper__contact-list text-center">
                      <div className="icon mb-3 mx-auto">
                        <img src="./../images/img (3).png" alt="" />
                      </div>
                      <div className="metadata">
                        <h5 className="bold font__size---28 text__28-1024 playfair">
                          Visit Us
                        </h5>
                        <p className="normal font__size--20 text__20-1024">
                          <a
                            href="https://www.google.com/maps/place/Eggs+%26+Waffles/@37.0143561,-121.588337,17z/data=!3m1!4b1!4m6!3m5!1s0x8091e30055e0ec0d:0x614c5c9c3aa24c51!8m2!3d37.0143562!4d-121.5834661!16s%2Fg%2F11vwsxvmpt?entry=ttu"
                            rel="noreferrer noopener"
                            target="_blank"
                          >
                            741 1st St.
                            <br className="d-md-block d-none" /> Gilroy CA 95020
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="wrapper__contact-list text-center">
                      <div className="icon mb-3 mx-auto">
                        <img src="./../images/img (1).png" alt="" />
                      </div>
                      <div className="metadata">
                        <h5 className="bold font__size---28 text__28-1024 playfair">
                          Contact Us
                        </h5>
                        <p className="normal font__size--20 text__20-1024">
                          <a href="tel:408-337-1800">408-337-1800</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3185.8088555230597!2d-121.58604102342798!3d37.014356172187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8091e30055e0ec0d%3A0x614c5c9c3aa24c51!2sEggs%20%26%20Waffles!5e0!3m2!1sen!2sin!4v1713112781555!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  title="Eggs & Waffles Location"
                  style={{ border: "2px solid #e1e1e1", borderRadius: "20px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>

              <div className="col-md-12">
                <hr className="my-5 hr__line-contact" />
              </div>

              <div className="col-md-10">
                <div className="wrapper__book-form bg__gray-2">
                  <div className="text-center mb-5">
                    <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb-0">
                      Write us a Message
                    </h2>
                  </div>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={schemas.contact}
                    onSubmit={handleSubmit}
                  >
                    {({ resetForm }) => (
                      <Form className="form">
                        <div className="form-group">
                          <label htmlFor="name"> Your Name </label>
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="name"
                            component="p"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email"> Email </label>
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="p"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="message"> Message </label>
                          <Field
                            name="message"
                            as="textarea"
                            rows={4}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="message"
                            component="p"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group pt-3 text-center">
                          {submitMessage.message && (
                            <div
                              className={`alert alert-${submitMessage.type} mb-3 text-center`}
                            >
                              {submitMessage.message}
                            </div>
                          )}
                          <button
                            type="submit"
                            className="btn btn__yellow color__black medium font__size--16 text__16-1024 shadow"
                            disabled={isSubmitting}
                          >
                            Send Message
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Contact;
