import React, { useState, Fragment } from "react";

import Testimoni from "../../component/Slider/Testimoni";
import Navbar from "./../../component/Navbar/Navbar";
import Reservation from "./../../component/Card/Reservation";
import FavoriteMenu from "../../component/Card/FavoriteMenu";
import Subscribe from "../../component/Card/Subscribe";
import Footer from "../../component/Footer/Footer";
const About = (props) => {
  const favoriteMenu = [
    {
      title: "Our Favorite Menu 1",
      price: 13,
      img: "./../images/Image.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
    {
      title: "Our Favorite Menu 2",
      price: 13,
      img: "./../images/Image-1.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
    {
      title: "Our Favorite Menu 3",
      price: 13,
      img: "./../images/Image-2.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
    {
      title: "Our Favorite Menu 4",
      price: 13,
      img: "./../images/Image-3.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
    {
      title: "Our Favorite Menu 5",
      price: 13,
      img: "./../images/Image-4.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
    {
      title: "Our Favorite Menu 6",
      price: 13,
      img: "./../images/Image-5.png",
      detail: "Amet minim mollit non deserunt ullamco.",
    },
  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div
          className="bg__black position-relative"
          style={{
            backgroundImage: "url('./../images/location/indoor-seating.jpeg')",
            backgroundPosition: "center 40%",
            backgroundSize: "cover",
          }}
        >
          <span className="backdrop-dark"></span>
          <div className="position-relative z-2">
            <Navbar />
            <div className="container">
              <h5 className="mb-0 color__white normal font__size--16 text__16-1024">
                <span className="opacity__6">Home</span> {" > "}
                <span className="color__yellow">About Us</span>
              </h5>
            </div>
            <section>
              <div className="container">
                <div className="text-center">
                  <h1 className="bold font__size--80 text__80-1024 text__80-md text__80-sm text__80-xs playfair color__white mb-4">
                    About Us
                  </h1>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    A cozy and inviting spot that serves up a hearty breakfast
                    and lunch menu, featuring a variety of eggs, waffles,
                    toasts, burgers, and more.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="pb-0">
          <div className="container">
            <div className="text-center">
              <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb-4">
                A cherished destination for satisfying, all-American cuisine
                with its classic diner charm.
              </h2>
              <div className="wrapper__video position-relative mt-5">
                <img
                  src="./../images/location/storefront.jpeg"
                  className="cover"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section__choose py-0">
          <div className="container position-relative">
            <div className="position-relative z-2">
              <h2 className="text-center bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb__5">
                Why People Choose Us
              </h2>

              <div className="row justify-content-center">
                <div className="col-md-11 col-xl-9">
                  <div className="row justify-content-center">
                    <div className="col-md-6 mb-4 mb-md-0">
                      <div className="wrapper__choose text-center">
                        <div className="icon mb-4 mx-auto">
                          <img src="./../images/vegetarian (1).png" alt="" />
                        </div>
                        <h5 className="bold font__size--24 text__24-1024 text__24-sm playfair mb-2">
                          Always Fresh Ingredients
                        </h5>
                        <p className="normal font__size--14 text__14-1024 color__gray-1">
                          Our commitment to using only the freshest and highest
                          quality ingredients ensures that every dish served is
                          bursting with flavor and made to perfection.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="wrapper__choose text-center">
                        <div className="icon mb-4 mx-auto">
                          <img src="./../images/food-tray.png" alt="" />
                        </div>
                        <h5 className="bold font__size--24 text__24-1024 text__24-sm playfair mb-2">
                          Variety for Every Palate
                        </h5>
                        <p className="normal font__size--14 text__14-1024 color__gray-1">
                          Our diverse menu caters to every palate, ensuring
                          there's something for everyone to enjoy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer class={"mt__5"} />
      </div>
    </Fragment>
  );
};

export default About;
