import React, { Fragment } from "react";
import Navbar from "./../../component/Navbar/Navbar";
import Footer from "../../component/Footer/Footer";
import FavoriteMenu from "../../component/Card/FavoriteMenu";
import menu from "../../constants/menu.constant";

const Menu = (props) => {
  const chooseMenu = menu;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black position-relative mb-5">
          <span className="backdrop"></span>
          <img src="./../images/menu-bg-1.jpeg" className="path__1" alt="" />
          <div className="position-relative z-2 mb__5">
            <Navbar />
            <div className="container">
              <h5 className="mb-0 color__white normal font__size--16 text__16-1024">
                <span className="opacity__6">Home</span> {" > "}
                <span className="color__yellow">Menu</span>
              </h5>
            </div>
            <section>
              <div className="container mb__5">
                <div className="text-center">
                  <h1 className="bold font__size--80 text__80-1024 text__80-md text__80-sm text__80-xs playfair color__white mb-4">
                    Menu
                  </h1>
                  <p className="normal font__size--28 text__28-1024 color__white">
                    The Flavor of America's Past and Present
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="pb-0">
          <div className="container">
            {chooseMenu.map((category, index) => {
              return (
                <div className="row mb-5">
                  <div className="col-md-12 text-center mb-3">
                    <h1 className="bold font__size--38 playfair color__yellow">
                      {category.title}
                    </h1>
                    <p className="normal font__size--16 text__16-1024 color__gray-1">
                      {category.detail}
                    </p>
                  </div>

                  {category.items.map((item) => {
                    return (
                      <div className="col-md-6 col-12">
                        <FavoriteMenu data={item} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </section>

        {/*<section>*/}
        {/*  <div className="container">*/}
        {/*    <Reservation />*/}
        {/*  </div>*/}
        {/*</section>*/}

        {/*<section>*/}
        {/*  <div className="container">*/}
        {/*    <Subscribe />*/}
        {/*  </div>*/}
        {/*</section>*/}

        <Footer />
      </div>
    </Fragment>
  );
};

export default Menu;
